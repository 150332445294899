@import '../base/base-typography.scss';
@import '../base/base-colors.scss';

@mixin res($size) {
    @if $size == phone {
        @media (max-width: $mobilePageWidth) { @content; }
    }
    @else if $size == tablet {
        @media (max-width: $tabletPageWidth) { @content; }
    }
    @else if $size == smallLaptop {
        @media (max-width: $laptopSmallPageWidth) { @content; }
    }
    @else if $size == desktop {
        @media (max-width: $websitePageWidth) { @content; }
    }
}

@mixin links (
    $linkColor:$primaryLinkColor,
    $visitedLinkColor:$primaryVisitedLinkColor,
    $hoverLinkColor:$primaryHoverLinkColor,
    $activeLinkColor:$primaryActiveLinkColor
) {
    a {
        color: $linkColor !important;
        transition: color 350ms;
        text-decoration: none;
        &:visited {
            color: $visitedLinkColor;
        }
        &:hover {
            color: $hoverLinkColor;
            text-decoration: underline;
        }
        &:active {
            color: $activeLinkColor;
        }
    }
}


@mixin largeTextTitle($direction:center, $headingTextColor:$secondaryHeadingColor, $margin: 48px 0 17px) {
    font-family: $quaternaryFont;
    font-size: 40px;
    color: $headingTextColor;
    text-transform: capitalize;
    font-weight:700;
    text-align: $direction;
    margin: $margin;
    justify-content: center;
    @include res(smallLaptop) {
        line-height: 50px;
        margin:20px 0 0;
        padding: 0;
    }
    @include res(phone) {
        padding: 0;
        line-height: 34px;
        font-size: 30px;
        margin:20px 0 0;
        display: flex;
    }
}
@mixin semiLargeTextTitle($direction:center, $headingTextColor:$headingColor) {
    font-family: $senaryFont;
    font-size: 27px;
    font-weight: 500;
    text-align: center;
    color: $headingTextColor;
    margin: 0;
}

@mixin mediumTextTitle($direction:left) {
    font-family: $primaryFont;
    font-size: 22px;
    color: $headingColor;
    font-weight:400;
    text-align: $direction;
}

@mixin smallTextTitle($direction:left,$margin: 40px 0) {
    @include links();
    font-family: $primaryFont;
    font-size: 20px;
    color: $headingColor;
    font-weight:700;
    text-align: $direction;
    margin: $margin;
}

@mixin tinyTextTitle($direction:center, $headingTextColor:$headingColor) {
    font-family: $primaryFont;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight:400;
    color:$headingTextColor;
    text-align: $direction;
    a{
        text-decoration: none;
        color: $primaryLinkColor;
    }
}

@mixin subTitle($direction:left,$margin:'20px 0') {
    @include links();
    font-family: $primaryFont;
    font-size: 17px;
    font-weight:400;
    color: $headingColor;
    text-align: $direction;
    margin:$margin;
    line-height: 22px;
    @include res(smallLaptop) {

        padding: 0 20px;
        font-size: 16px;
    }
    //a {
    //    text-decoration: none;
    //}
}

@mixin primaryBodyFont($direction:left) {
    font-family: $primaryFont;
    color:$secondaryTextColor;
    font-size: $bodyFontSize;
    text-align: $direction;
}

@mixin sectionTitle($direction:center) {
    font-family: $secondaryFont;
    font-size: 16px;
    font-weight:500;
    color: $primaryTextColor;
    text-align: $direction;
    //text-transform: uppercase;

}
@mixin sectionText($direction:left) {
    font-family: $primaryFont;
    font-size: 15px;
    font-weight:400;
    color: $headingColor;
    margin: 5px 0;
    text-align: $direction;
}
@mixin buttonText() {
    font-family: $primaryFont;
    font-size: 15px;
    font-weight:400;
}
@mixin linkText() {
    font-family: $primaryFont;
    color: $primaryLinkColor;
    $primaryLinkHoverColor: #4363bc;
}
@mixin articleText() {
    font-family: $articleFont;
    font-size: 20px;
    line-height: 2.1rem;
}
@mixin captionText() {
    font-family: $captionFont;
    font-size: 14px;
    line-height: 1rem;
    color:#455275;
}

//THEMES
$primaryThemeColor: #000000;
$secondaryThemeColor: #188cff;
$secondaryThemeColor_dark-mode: #ffffff;


//FONTS -----
$primaryTextColor: #404040;
$secondaryTextColor: #000000;
$primaryTextColor_dark-mode: #ececec;

$generalBackgroundColor: #f9f9f9;
$secondaryBackgroundColor:#f3f3f3;
$alternateBackgroundColor: #7d7d7d;

$headingColor: #505050;
$secondaryHeadingColor: #373737;
$tertiaryHeadingColor: #7e7a5b;
$headingColor_dark-mode: #ececec;

$subHeadingColor: #6d6d6d;

$primaryLinkColor: #9e7d03;
$primaryLinkHoverColor: darken($primaryLinkColor, 30%);

$linkActiveColor: #bf0109;

$secondaryLinkColor: #0072d4;
$secondaryLinkColor_dark-mode: #5db3ff;
$secondaryLinkHoverColor: #0f6599;

//BUTTONS -----
$primaryButtonColor: #188cff;
$secondaryButtonColor: #615b50;
$tertiaryButtonColor: #3f4040;

$secondaryButtonColor_dark-mode: #282828;
$tertiaryButtonColor_dark-mode: #3f4040;

$lineColor: #999999;
$lineColorUnder: #ededed;
$iconDisable: #d7d7d7;
$alternativeRow: #fafafa;
$iconActive: #c0991b;

//BODY COLOR -----
$primaryBodyColor: #cc0000;
$secondaryBodyColor: #000000;
$tertiaryBodyColor: #EFEFEF;
$quaternaryBodyColor:#EFEFEF;
$quinaryBodyColor:#EFEFEF;

//FOOTER-----

//font colors
$footerLinkColor: #676a71;
$footerLinkHoverColor: darken($footerLinkColor, 10%);;
$bottomTextColor: #707a83;
$textColor: #FFFFFF;
//backgroundColors
$footerBackgroundColor: #1e1e1e;
$footerTileColour: #e6e4e4;
$footerTileColour: #e6e4e4;
$contactBackground:#f9f9f9;

//LINKS
$LinkColor: $primaryLinkColor;
$LinkColor_dark-mode:#8cb4ee;
$primaryLinkColor: $LinkColor;
$primaryVisitedLinkColor: darken($primaryLinkColor, 10%);
$primaryHoverLinkColor: lighten($primaryLinkColor, 10%);
$primaryActiveLinkColor: darken($primaryLinkColor, 20%);


//HEADER-----

//background colors
$headerBackgroundColor: #fdfeff;
$headerActiveBackgroundColor: #f9f9f9;
$linkBlockBackgroundColor: #fdfeff;
$headerTopBarBackgroundColor:#fdfeff;

//HEADER LINK TILES
$linkTileBackgroundColor: transparent;
$linkTileActiveBackgroundColor: #fdfeff;

//HEADER FONT COLORS
$headerLinkTileColor: #383838;
$headerLinkTileColor_dark-mode: #7c92ad;
$headerLinkTileActiveColor: $primaryLinkColor;
$headerLinkTileActiveColor_dark-mode: $secondaryThemeColor;
$headerLinkTileHoverColor:darken($headerLinkTileColor, 20%);

//shadow underlines
$linkTileActiveUnderline: 0;
$linkTileHoverUnderline: 0;

//border underlines
$linkTileActiveBorderUnderline: 1px solid $primaryLinkColor;
$linkTileHoverBorderUnderline: 0;

:export {
  secondaryBackgroundColor: $secondaryBackgroundColor;
  alternateBackgroundColor: $alternateBackgroundColor;
  headerBackgroundColor:$headerBackgroundColor;
  primaryLinkColor:$primaryLinkColor;
  footerBackgroundColor:$footerBackgroundColor;
  linkTileActiveBorderUnderline:$linkTileActiveBorderUnderline;
  headerLinkTileActiveColor:$headerLinkTileActiveColor;
  linkTileHoverUnderline:$linkTileHoverUnderline;
  linkTileBackgroundColor:$linkTileBackgroundColor;
  headerLinkTileColor:$headerLinkTileColor;
  headerLinkTileHoverColor:$headerLinkTileHoverColor;

}

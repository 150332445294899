.loaderContainer{
  width: 100%;
  padding: 150px 0 150px;
  display: flex;
  justify-content: center;
  .large-select-0{fill:#BE9600;}
  .large-select-1{fill:#181818;}
  .large-select-2{fill:#363636;}
}


